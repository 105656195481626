import { Modal, ModalFooter, ModalBody } from "reactstrap";
import { useState } from "react";
import * as promotionTypes from "../utils/constants/promotionTypes.js";
import PendingModalContent from "./ModalContents/PendingModalContent";
import UpcomingModalContent from "./ModalContents/UpcomingModalContent.jsx";
import ActiveModalContent from "./ModalContents/ActiveModalContent.jsx";
import "../styles/UniversalModal.css";

// Universal Modal Created to have a standard look between informational modals.
// The contents of the modal should be created in their own component within the the ModalContents Folder
// Make sure to pass in SetShow, header, and contentType at a minimum.
const UniversalModal = ({
  setShow,
  header,
  contentType,
  incentive,
  promotionDescription,
  daysRemaining
}) => {
  const [modal, setModal] = useState(true);
  const toggle = () => {
    setModal(!modal);
    setShow(false);
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      fade={false}
      contentClassName="universal-modal-style"
    >
      <div>
        <h2 className="universal-modal-header">{header}</h2>
        <span className="icon-close universal-modal-close" onClick={toggle} />
      </div>
      <ModalBody className="universal-modal-body">
        {contentType === promotionTypes.PendingPromotion && (
          <PendingModalContent 
            promotionDescription={promotionDescription}
            daysRemaining={daysRemaining}
          />
        )}
        {contentType === promotionTypes.UpcomingPromotion && (
          <UpcomingModalContent
            promotionDescription={promotionDescription}
            daysRemaining={daysRemaining}
          />
        )}
        {contentType === promotionTypes.ActivePromotion && (
           <ActiveModalContent
            incentive={incentive}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-primary" onClick={toggle}>
          Close
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default UniversalModal;