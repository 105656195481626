import React, { useEffect, useState } from "react";
import { convertDate, getDaysRemaining } from "../../helpers/incentiveHelper";

const ActiveModalContent = ({ incentive }) => {
  const [rewardType, setRewardType] = useState("");
  const [promotionType, setPromotionType] = useState("");

  useEffect(() => {
    switch (incentive.incentiveType.toUpperCase()) {
      case "GAMIFICATION":
        setRewardType("spins");
        break;
      default:
        setRewardType("payouts");
        break;
    }

    switch (incentive.promotionType.toUpperCase()) {
      case "NEWBUSINESS":
        setPromotionType("policies");
        break;
      case "QUOTES":
        setPromotionType("quotes");
        break;
      default:
        setPromotionType("events");
        break;
    }
  }, []);

  return (
    <>
      {getDaysRemaining(incentive.endDate) > 0 && (
        <div>
          Promotion Dates: {convertDate(incentive.startDate)} -{" "}
          {convertDate(incentive.endDate)}
        </div>
      )}
      {getDaysRemaining(incentive.endDate) <= 0 && (
        <div>
          Promotion Ended. Redeem {rewardType} by{" "}
          {convertDate(incentive.redemptionEndDate)}
        </div>
      )}
      <div className="ActivePromotion-modal-body">
        <br />
        <p>
          <b>Line of Buisness:</b> {incentive.lineOfBusiness}
        </p>
        {incentive.lineOfBusiness !== incentive.product && (
          <p>
            <b>Product: </b>
            {incentive.product}
          </p>
        )}
        <b>Description:</b>
        <p>{incentive.description}</p>
        <p className="ActivePromotion-modal-footer-text">
          Earn {rewardType} through {promotionType}
        </p>
        {getDaysRemaining(incentive.endDate) > 0 && rewardType === "spins" && (
          <p className="ActivePromotion-modal-footer-text">
            Any leftover {rewardType} will expire:{" "}
            {convertDate(incentive.redemptionEndDate)}
          </p>
        )}
      </div>
    </>
  );
};

export default ActiveModalContent;
