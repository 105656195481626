import { useEffect, useState, useContext } from "react";
import * as rewardsApi from "../services/ApiServices/ApiService";
import { logError } from "../services/ApiServices/LoggingService";
import { FeatureFlagContext } from "../context/FeatureFlagContextProvider";
import PrimaryNavigation from "./PrimaryNavigation";

const Header = () => {
  const [gatewayUrl, setGatewayUrl] = useState(null);
  const [gameOnHomePageFlag, setGameOnHomePageFlag] = useState(false);
  const featureFlags = useContext(FeatureFlagContext);

  const handleFeatureFlags = () => {
    setGameOnHomePageFlag(featureFlags?.PrizeWheelOnHomePageFlag);
  };

  const getFederatedLoginUrl = () => {
    const handleError = (error) => {
      logError(
        error,
        "error when calling rewardsApi.getExternalUrls",
        "getFederatedLoginUrl in Header.jsx"
      );
    };
    const handleSuccess = (response) => {
      setGatewayUrl(response.gatewayUrl);
    };

    rewardsApi.getExternalUrls(handleSuccess, handleError);
  };

  useEffect(() => {
    getFederatedLoginUrl();
  }, []);

  useEffect(() => {
    handleFeatureFlags();
  }, [featureFlags]);

  return (
    <>
      <header id="primary-header" role="banner">
        <div className="header-logo" data-testid="header-logo">
          <a className="icon-geico" aria-label="GEICO Home" href="/"></a>
        </div>

        <div className="header-links">
          <ul>
            <li className="gateway-link" data-testid="gateway-link">
              {gameOnHomePageFlag && gatewayUrl && (
                <a href={gatewayUrl} target="_blank" rel="noreferrer">
                  <span className="icon-profile"></span>
                  <span className="header-link">Gateway</span>
                </a>
              )}
            </li>
            <li className="menu" data-testid="menu">
              <a
                data-side-panel-trigger="menu"
                className="hamburger-menu"
                href="/"
              >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span className="header-link">Menu</span>
              </a>
            </li>
          </ul>
        </div>
      </header>
      <PrimaryNavigation />
    </>
  );
};

export default Header;
