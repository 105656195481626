import { useState, useEffect, useContext } from "react";
import { UserPendingPromotionsContext } from "../../context/userContext";
import { FeatureFlagContext } from "../../context/FeatureFlagContextProvider";
import UpcomingPromotionCard from "./UpcomingPromotionCard";
import PendingPromotionsCard from "./PendingPromotionsCard";
import * as rewardsApi from "../../services/ApiServices/ApiService";
import { logError } from "../../services/ApiServices/LoggingService";
import { getDaysRemaining } from "../../helpers/incentiveHelper";
import "@gdk/card-selections/dist/styles/gdk-card-selections.css";

const UpcomingPromotions = () => {
  const userPendingPromotions = useContext(UserPendingPromotionsContext);
  const featureFlags = useContext(FeatureFlagContext);
  const [availableIncentives, setAvailableIncentives] = useState(null);
  const [pendingIncentives, setPendingIncentives] = useState(null);
  const [gameOnHomePageFlag, setGameOnHomePageFlag] = useState(false);
  const [pendingPromosFlag, setPendingPromosFlag] = useState(false);
  const anyIncentivesCheck =
    ((availableIncentives && availableIncentives.length > 0) ||
      (userPendingPromotions && userPendingPromotions.length > 0)) &&
    gameOnHomePageFlag && !pendingPromosFlag;
  const availableIncentivesCheck =
    availableIncentives && availableIncentives.length > 0;

  const getAvailableIncentives = async () => {
    const handleError = (error) => {
      logError(error, "error when calling rewardsApi.getAvailableIncentives", "getAvailableIncentives in UpcomingPromotions.jsx");
    };
    const handleSuccess = (responseData) => {
      setAvailableIncentives(responseData.response);
    };
    await rewardsApi.getAvailableIncentives(handleSuccess, handleError);
  };

  useEffect(() => {
    getAvailableIncentives();
    setGameOnHomePageFlag(featureFlags?.PrizeWheelOnHomePageFlag);
    setPendingPromosFlag(featureFlags?.PendingPromotionsFlag);
    setPendingIncentives(userPendingPromotions);
  }, [userPendingPromotions, featureFlags]);

  return (
    <div id="upcomingSection" className="row">
      {(anyIncentivesCheck || availableIncentivesCheck) && (
        <div>
          <h4 className="title-padding">Upcoming Promotions</h4>
          <div className="row">
            {availableIncentives != null && 
             availableIncentives.map(function (data, index) {
              return (
                <div className="col-sm-6" key={data.id}>
                  <UpcomingPromotionCard
                    id={data.id}
                    cardTitle={data.name}
                    promotionDescription={data.description}
                    daysRemaining={getDaysRemaining(data.enrollmentEndDate)}
                    index={index}
                  />
                </div>
              );
            })}
            {!pendingPromosFlag && pendingIncentives != null &&
              pendingIncentives.map(function (data, index) {
                return (
                  <div className="col-sm-6" key={data.id}>
                    <PendingPromotionsCard
                      id={data.id}
                      cardTitle={data.name}
                      promotionDescription={data.description}
                      daysRemaining={getDaysRemaining(data.startDate)}
                      index={index}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};

export default UpcomingPromotions;
