import { useState, useEffect, useContext } from "react";
import { Wheel } from "react-custom-roulette";
import "../styles/wheel.css";
import * as rewardsApi from "../services/ApiServices/ApiService";
import { logError } from "../services/ApiServices/LoggingService";
import GameModal from "./GameModal";
import WheelTick from "../sounds/wheel-tick-v2.wav";
import QuantumMetrics from "./QuantumMetrics";
import { FeatureFlagContext } from "../context/FeatureFlagContextProvider";
import PrizeWheelDisabled from "../images/PrizeWheelDisabled.svg";

const pointer = {
  src: "/images/pointer.png",
  style: {
    transform: "rotate(-11deg) scale(1.15)",
    margin: "15px",
  },
};

// This version of the wheel is ONLY used on the Game page
// Called in Game.jsx when gameOnHomePageFlag is OFF (false)
const PrizeWheelGamePage = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [numberOfSpins, setNumberOfSpins] = useState(props.numberOfSpins);
  const [prizeWheelSoundFlag, setPrizeWheelSoundFlag] = useState(false);
  const [muteWheelSound, setMuteWheelSound] = useState(false);
  const [disableMuteButton, setDisableMuteButton] = useState(false);
  const [showPrizeWheelDisabled, setShowPrizeWheelDisabled] = useState(false);
  const [processingData, setProcessingData] = useState(false);
  const featureFlags = useContext(FeatureFlagContext);
  const chosenIncentiveId = props.incentiveId;
  const payoutOptions = props.payoutOptions;

  useEffect(() => {
    setPrizeWheelSoundFlag(featureFlags?.PrizeWheelSoundFlag);
  }, [featureFlags]);

  useEffect(() => {
    setNumberOfSpins(props.numberOfSpins);
  }, [props.numberOfSpins]);

  useEffect(() => {
    setMuteWheelSound(props.muteWheelSound);
  }, [props.muteWheelSound]);

  const getPayoutToken = async (incentiveId) => {
    let apiCallSuccess = false;
    let payoutResult;

    const handleError = (error) => {
      logError(
        error,
        "error when calling rewardsApi.getPayoutToken",
        "getPayoutToken in PrizeWheel.jsx"
      );
    };

    const handleSuccess = (responseData) => {
      apiCallSuccess = true;
      if (responseData.status === "Failure" || responseData.status === 500) {
        window.location.href = "/error";
      } else {
        payoutResult = responseData.response.payoutAmount;
      }
    };

    await rewardsApi.getPayoutToken(incentiveId, handleSuccess, handleError);
    if (apiCallSuccess) {
      return payoutResult;
    }
  };

  const handleSpinClick = async () => {
    const audio = new Audio(WheelTick);

    setProcessingData(true);
    setDisableMuteButton(true);
    if (!mustSpin) {
      const payoutAmount = await getPayoutToken(chosenIncentiveId);
      setPrizeNumber(
        payoutOptions
          .map(function (o) {
            return o.option;
          })
          .indexOf("$" + payoutAmount)
      );

      setNumberOfSpins(numberOfSpins - 1);
      setModalVisible(false);
      setMustSpin(true);

      if (prizeWheelSoundFlag && !muteWheelSound) {
        audio.play();
      }
    }
  };

  return (
    <>
      <div>
        <h4 data-testid="prize-wheel-spins-left-test">
          You have <span className="money-green">{numberOfSpins.toLocaleString()}</span> {numberOfSpins === 1 ? "spin" : "spins"} remaining.
        </h4>
        <br />
        <br />
      </div>
      <div className="game-segment">
        {!showPrizeWheelDisabled ? (
          <div className="wheel">
            <div className="wheel-base" data-testid="spinner-wheel-base">
              <Wheel
                mustStartSpinning={mustSpin}
                prizeNumber={prizeNumber}
                spinDuration={0.7}
                data={payoutOptions}
                radiusLineColor="white"
                radiusLineWidth={7}
                outerBorderWidth={2}
                outerBorderColor="white"
                perpendicularText={false}
                fontSize={28}
                textDistance={75}
                textColors={["white"]}
                backgroundColors={["#1D4A96", "#573E94", "#1D4A96", "#9380C7"]}
                pointerProps={pointer}
                onStopSpinning={() => {
                  setMustSpin(false);
                  setTimeout(() => {
                    setModalVisible(true);
                    setProcessingData(false);
                    setDisableMuteButton(false);
                    if (numberOfSpins === 0) {
                      setShowPrizeWheelDisabled(true);
                    }
                  }, 800);
                }}
              />
            </div>
            <div className="prize-wheel-button">
              <div
                className="inner-circle-small"
                onClick={handleSpinClick}
                disabled={processingData}
              >
                <div
                  className="button-text"
                  data-testid="prize-wheel-button-test"
                >
                  <span className="button-text-top">Push to</span>
                  <span className="button-text-bottom">Spin!</span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="wheel">
            {showPrizeWheelDisabled && (
              <img src={PrizeWheelDisabled} alt="No Tokens Prize Wheel" />
            )}
          </div>
        )}
      </div>
      {prizeWheelSoundFlag && (
        <div className="mute-button">
          {!disableMuteButton ? (
            <span
              onClick={() => {
                setMuteWheelSound(!muteWheelSound);
              }}
            >
              {muteWheelSound ? (
                <span
                  aria-label="Unmute wheel noise"
                  className="geico-icon geico-icon--actionable icon-muted-speaker"
                ></span>
              ) : (
                <span
                  aria-label="Mute wheel noise"
                  className="geico-icon geico-icon--actionable icon-speaker"
                ></span>
              )}
            </span>
          ) : (
            <>
              {muteWheelSound ? (
                <span
                  aria-label="Disabled unmute wheel noise"
                  className="geico-icon icon-muted-speaker"
                  style={{ color: "gray" }}
                ></span>
              ) : (
                <span
                  aria-label="Disabled mute wheel noise"
                  className="geico-icon icon-speaker"
                  style={{ color: "gray" }}
                ></span>
              )}
            </>
          )}
        </div>
      )}
      {modalVisible === true && numberOfSpins >= 0 && (
        <GameModal
          prizeAmount={payoutOptions[prizeNumber].option}
          numberOfSpins={numberOfSpins}
        />
      )}
      <QuantumMetrics />
    </>
  );
};

export default PrizeWheelGamePage;
